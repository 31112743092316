.App {
	text-align: center;
	height: 100%;
	position: relative;
}

html, body, #root{
	height: 100%;
}

p {
	display: block;
	margin-block-start: 0;
	margin-block-end: 0;
	margin-inline-start: 0px;
	margin-inline-end: 0px;
}
h1{
	font-family: SF Pro Text;
	font-weight: bold;
	font-size: 34px;
	line-height: 41px;
	text-align: left;
}
@media (max-width: 351px) {
	h1{
		font-size: 32px;
	}
}
.leaflet-container {
	height: 100%;
}