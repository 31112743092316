@font-face {
	font-family: 'SF Pro Text';
	src: url('./fonts/FontsFree-Net-SFProText-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Pro Display Regular';
	src: url('./fonts/SF Pro Display Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'SF Compact Rounded';
	src: url('./fonts/SFCompactRounded-Regular.otf') format('opentype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
@font-face {
	font-family: 'RoadNumbers';
	src: url('./fonts/RoadNumbers2.0.otf') format('opentype'),
		url('./fonts/RoadNumbers2.0.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

*{padding: 0;margin: 0;border: 0;}
*,*:before,*:after{
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	box-sizing: border-box;
}
:focus,:active{outline: none;}
a:focus,a:active{outline: none;}
nav,footer,header,aside{display: block;}
html, body {
	height: 100%;
	width: 100%;
	font-size: 100%;
	line-height: 1;
	font-size: 14px;
	-ms-text-size-adjust: 100%;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}
input,button,textarea{font-family:inherit;}
input::-ms-clear{display: none;}
button{cursor: pointer;}
button::-moz-focus-inner{padding:0;border:0;}
a,a:visited{text-decoration: none;}
a:hover{text-decoration: none;}
ul li{list-style: none;}
img{vertical-align: top;}

html::-webkit-scrollbar {
	width: 4px;
	height: 4px;
}
	
html::-webkit-scrollbar-track {
	background-color: #FFF;
}
	
html::-webkit-scrollbar-thumb {
	background-color: #D9DBE4;
	border-radius: 5px;
}